<template>
  <div class="bg-grey">
    <div class="flex-box">
      <div class="lt">图片标题</div>
      <div class="rt">
        <a-input
          placeholder="请输入"
          style="width: 400px"
          @change="onTitle"
          v-model="newTitle"
        />
      </div>
    </div>
    <Uploader
      list-type="picture-card"
      :customFileList="imgList"
      @preview="handlePreview"
      @uploadChange="uploadChange"
      accept=".jpg, .jpeg, .png"
      @uploadLoainng="uploadLoainng"
    >
      <slot>
        <div v-if="resultImgList.length < 5">
          <a-icon type="plus"/>
          <div class="ant-upload-text">上传</div>
        </div>
      </slot>
    </Uploader>
    <span class="close" @click="deletePic"><a-icon type="close"/></span>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage"/>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: 'uploadPicsAndTitle',
  components: {
    Uploader: () => import('@/components/upload/Uploader'),
  },
  props: {
    title: {
      type: String,
      default: () => '',
    },
    fileList: {
      type: Array,
      default: () => undefined,
    },
    index: {
      type: Number,
      default: () => undefined,
    },
  },
  data() {
    return {
      previewVisible: false,
      previewImage: '',
      imgList: [],
      newTitle: '',
      resultImgList: [],
    };
  },
  watch: {
    fileList: {
      handler() {
        this.imgList = this.fileList;
        this.resultImgList = this.fileList;
      },
    },
    title: {
      handler() {
        this.newTitle = this.title;
      },
    },
  },
  mounted() {
    this.imgList = this.fileList;
    this.resultImgList = this.fileList?.map((s) => s.id);
    this.newTitle = this.title;
  },
  methods: {
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange({ fileList }) {
      this.fileList = fileList;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    uploadChange(value) {
      this.resultImgList = value.map(
        (s) => s?.response?.data?.file_resource_id ?? s?.file_resource_id,
      );
      this.$emit('uploadChange', {
        path: this.resultImgList,
        title: this.newTitle,
        index: this.index,
      });
    },
    onTitle() {
      this.$emit('uploadChange', {
        path: this.resultImgList,
        title: this.newTitle,
        index: this.index,
      });
    },
    handleCancel() {
      this.previewVisible = false;
    },
    deletePic() {
      this.$emit('deletePic', {
        index: this.index,
      });
    },
    uploadLoainng(value) {
      this.$emit('uploadLoainng', value);
    },
  },
};
</script>

<style lang="less" scoped>
.bg-grey {
  background-color: rgb(242, 242, 242);
  padding: 15px;
  position: relative;
  margin-top: 10px;

  .flex-box {
    display: flex;
    padding-bottom: 10px;

    .rt {
      flex: 1;
    }

    .lt {
      padding-right: 10px;
    }
  }

  .close {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 15px;
    height: 15px;
    display: block;
    cursor: pointer;
  }
}
</style>
